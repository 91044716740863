// Material tab group doesn't have ability to deselect initial tab.
.mat-tab-group.custom-tab-group.no-active-tab-group .mat-tab-labels > .mat-tab-label:first-child {
  display: none;
}

.mat-tab-group.custom-tab-group .mat-tab-labels > .mat-tab-label:first-child {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
}

.mat-tab-group.custom-tab-group .mat-tab-labels > .mat-tab-label:last-child {
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

// Whether mat-tab group with first non-active tab, then we should set border radius for second child.
.mat-tab-group.custom-tab-group.no-active-tab-group .mat-tab-labels > .mat-tab-label:nth-child(2) {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-right: 1px var(--divider-color) solid;
}

.mat-tab-group.custom-tab-group .mat-tab-labels  .mat-tab-label.mat-tab-disabled {
  cursor: not-allowed;
}

.mat-tab-group.custom-tab-group .mat-tab-label {
  opacity: 1;
}

.mat-tab-group.custom-tab-group .mat-tab-labels > .mat-tab-label.mat-tab-disabled {
  background-color: var(--surface-background-color);
  color: var(--base-secondary-color);
  border: 1px solid var(--base-secondary-color);
}

.mat-tab-group.custom-tab-group .mat-tab-header {
  border-radius: var(--radius);
}

.mat-tab-group.custom-tab-group .mat-ink-bar {
  display: none;
}
