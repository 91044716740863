#payFields-iframe-name,
#payFields-iframe-number,
#payFields-iframe-expiration,
#payFields-iframe-cvv,
#payFields-iframe-routing,
#payFields-iframe-account_number,
#payFields-iframe-account_type {

  // Due to overflow hidden in the library, the display of errors is cut off.
  height: 80px!important;

  // Prevents cropping of the credit card icon.
  margin-top: -10px;
}
