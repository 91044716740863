/* Custom application color and font settings */
:root {
  /* Font */
  --font-size: 15px;
  --font-family: "Roboto", sans-serif;
  --h1-font-size: 24px;
  --h2-font-size: 14px;
  color: var(--base-color);

  /* Styles that don't represent the brand (stole this phrase from material.io) */

  /* Base color for a body text. */
  --base-color: rgba(0, 0, 0, 0.87);

  /** Base secondary color for small text elements for user information. */
  --base-secondary-color: rgba(0, 0, 0, 0.38);

  /* Background color appears behind scrollable content. */
  --base-background-color: #eeeeee;

  /** Secondary background color. */
  --secondary-background-color: #fafafa;

  /* Color for a text that's not important or it should not draw attention. */
  --faint-color: rgba(0, 0, 0, 0.54);

  /* Font color for surface background. */
  --surface-font-color: rgba(0, 0, 0, 0.87);
  /* Affect surfaces of components, such as cards, sheets, and menus */
  --surface-background-color: #ffffff;

  /* Font color for error messages. */
  --error-font-color: #ff1744;
  /* In case error is a block with a message inside. */
  --error-background-color: #fafafa;

  /* Elements spacing. */
  --space-m: 15px;
  --space-xs: calc(var(--space-m) * 0.5);
  --space-s: calc(var(--space-m) * 0.8);
  --space-l: calc(var(--space-m) * 1.5);
  --space-xl: calc(var(--space-m) * 2);

  /* Brand colors */
  --primary-color: rgb(0, 170, 79);
  --primary-dark-color: #14802d;
  --primary-light-color: #78c288;
  --primary-light-second-color: #18cb68;
  --primary-light-background-color: #d4ebda;
  --primary-contrast-color: white;
  --primary-dark-contrast-color: white;
  --primary-light-contrast-color: black;

  --accent-color: #da9233;
  --accent-dark-color: #cc7e18;
  --accent-light-color: #e4c8a3;
  --accent-contrast-color: white;
  --accent-dark-contrast-color: white;
  --accent-light-contrast-color: black;

  --warn-color: #da3e33;
  --warn-dark-color: #aa190f;
  --warn-light-color: #e4746c;
  --warn-contrast-color: white;
  --warn-dark-contrast-color: white;
  --warn-light-contrast-color: black;

  /* Util properties. Util properties must not define new values. */
  --radius: 8px;
  --border-color: rgba(0, 0, 0, 0.12);
  --divider-color: rgba(0, 0, 0, 0.24);
  --links-color: rgb(29, 90, 197);
  --link-border-color: rgb(221, 221, 221);
  --link-background-color: rgb(238, 238, 238);
  --link-secondary-color: rgba(255, 255, 255, 0.7);
  --placeholder-color: rgba(0, 0, 0, 0.38);
  --buttons-font-size: 16px;
  --small-font-size: 12px;
  --active-nav-link-color: var(--primary-color);
  --interactive-user-text-color: rgba(0, 0, 0, 0.65);
  --disabled-text-color: rgba(255, 255, 255, 0.5);
  --input-disabled-background-color: rgb(238, 238, 238);
  --primary-light-hover-color: rgba(23, 146, 51, 0.05);
  --dark-hover-color: rgba(0, 0, 0, 0, 0.5);
  --secondary-background-border-color: rgba(0, 0, 0, 0.06);
  --cancel-button-color: rgba(0, 0, 0, 0.24);
  --form-field-active-color: rgb(23, 146, 51);
  --box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.03),
    0 1px 1px 0 rgba(0, 0, 0, 0.24),
    0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
