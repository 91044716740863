
.page-content-title {
  margin: 0;
  margin-bottom: var(--space-m);
  font-size: 17px;
  font-weight: 400;
}

.page-content-subtitle {
  margin: 0;
  margin-bottom: var(--space-m);
  color: var(--base-secondary-color);
}

.page-content-subtitle.error,
.error {
  color: var(--error-font-color);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row > * {
  width: 31%;
}

.two-element-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.two-element-row > * {
  width: 47%;
}

.asterisk {
  margin-left: 1px;
  color: var(--base-secondary-color);
}

@media (max-width: 1023px) {
  .row,
  .two-element-row {
    flex-direction: column;
  }

  .row > * ,
  .two-element-row > * {
    width: 100%;
  }
}
