// Angular custom theme.

// Custom application styles.
@import 'variables.scss';
@import './angular-material-custom-theme.scss';
@import 'elements/style.scss';
@import 'utils.scss';

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: var(--font-size);
  font-family: var(--font-family);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--base-background-color);
}

h1 {
  font-size: var(--h1-font-size);
}

h2 {
  font-size: var(--h2-font-size);
}

.bold {
  font-weight: bold;
}
