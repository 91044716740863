:root {
  --snackbar-default-background-color: rgb(109, 109, 109);
}

.snackbar {
  top: -5px;
  color: var(--primary-contrast-color);
  background-color: var(--snackbar-default-background-color);
}

.default-snackbar {
  bottom: 0;
  color: var(--primary-contrast-color);
}
