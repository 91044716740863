/* Define basic styles for buttons and links as buttons.
  It contains 3 main types of buttons, the name of which
  corresponds to the material: flat, stroked and basic. */

:root {
  --flat-button-hover-color: rgb(0, 142, 66);
  --flat-button-active-color: rgb(0, 108, 50);
  --flat-warn-button-hover-color: rgb(227, 0, 44);
  --flat-warn-button-active-color: rgb(205, 0, 40);
  --stroked-button-border-color: rgb(23, 146, 51);
  --basic-button-background-hover-color: rgb(250, 250, 250);
}

.button {
  min-width: 150px;
  padding: var(--space-m);
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-weight: 700;
  font-size: var(--buttons-font-size);
  color: inherit;
  text-align: center;
  border-radius: var(--radius);
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.button:hover,
.button:focus {
  text-decoration: none;
  outline: none;
}

.button:disabled:not(.protctc-loading) {
  cursor: not-allowed;
}

/* Flat button. */

.button.flat {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
}

.button.flat:hover,
.button.flat:focus  {
  background-color: var(--flat-button-hover-color);
}

.button.flat:active {
  background-color: var(--flat-button-active-color);
}

.button.flat:disabled:not(.protctc-loading) {
  background-color: var(--base-secondary-color);
  color: var(--disabled-text-color);
}

.button.flat.warn {
  background-color: var(--error-font-color);
}

.button.flat.warn:hover,
.button.flat.warn:focus {
  background-color: var( --flat-warn-button-hover-color);
}

.button.flat.warn:active {
  background-color: var(--flat-warn-button-active-color);
}

/** Stroked button. */

.button.stroked {
  border: 1px solid var(--stroked-button-border-color);
  background-color: transparent;
}

.button.stroked:hover,
.button.stroked:focus {
  color: var(--stroked-button-border-color);
}

.button.stroked:active:not(:disabled) {
  background-color: var(--primary-light-background-color);
  color: var(--base-color);
}

.button.stroked:disabled:not(.protctc-loading) {
  border-color: var(--border-color);
  color: var(--base-secondary-color);
}

/* Basic button. */

.button.basic {
  background-color: transparent;
}

.button.basic:hover,
.button.basic:focus {
  background-color: var(--basic-button-background-hover-color);
}

.button.basic:active {
  background-color: var(--base-background-color);
}

.button.basic:disabled:not(.protctc-loading) {
  color: var(--base-secondary-color);
  background: none;
}

.button.basic.warn {
  color: var(--error-font-color);
}

.link-button {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  text-align: start;
  color: var(--base-secondary-color);
  cursor: pointer;
  text-decoration: underline;
  font-size: 1.1rem;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}

.link-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.link-button {
  -webkit-appearance: none;
}
