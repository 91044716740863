.mat-tooltip.custom-tooltip {
  font-size: var(--font-size);
  color: var(--primary-contrast-color);
  background-color: var(--primary-color);
  position: relative;
  overflow: initial;
  border-radius: var(--radius);
}

.mat-tooltip.custom-tooltip::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  top: -0.5rem;
  right: calc(50% - 0.5rem);
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid var(--primary-color);
  transform: rotate(0);
}
