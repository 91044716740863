.mat-input-element.protctc-async-input-valid,
.mat-input-element.protctc-async-input-pending,
.mat-input-element.protctc-async-input-invalid {
  max-width: 90%;
  padding-right: 10%;;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: right 8px center;
}

.mat-input-element.protctc-async-input-valid {
  background-image: url("/assets/icons/input-valid.svg");
}

.mat-input-element.protctc-async-input-pending {
  background-size: auto;
  background-image: url("/assets/icons/input-pending.svg");
}

.mat-input-element.protctc-async-input-invalid {
  background-image: url("/assets/icons/input-invalid.svg");
}
