.surface-card {
  position: relative;
  overflow: hidden;
  background-color: var(--surface-background-color);
  border-radius: 4px;
  box-shadow: var(--box-shadow);
}

.surface-card.required::before {
  content: "* Required";
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: var(--small-font-size);
  font-weight: 500;
  color: var(--base-secondary-color);
}
