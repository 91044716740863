@use "@angular/material" as mat;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$protct-primary: (
  main: var(--primary-color),
  dark: var(--primary-dark-color),
  light: var(--primary-light-color),
  contrast: (
    main: var(--primary-contrast-color),
    dark: var(--primary-dark-contrast-color),
    light: var(--primary-light-contrast-color),
  ),
);

$protct-accent: (
  main: var(--accent-color),
  dark: var(--accent-dark-color),
  light: var(--accent-light-color),
  contrast: (
    main: var(--accent-contrast-color),
    dark: var(--accent-dark-contrast-color),
    light: var(--accent-light-contrast-color),
  ),
);

$protct-warn: (
  main: var(--warn-color),
  dark: var(--warn-dark-color),
  light: var(--warn-light-color),
  contrast: (
    main: var(--warn-contrast-color),
    dark: var(--warn-dark-contrast-color),
    light: var(--warn-light-contrast-color),
  ),
);

/*
  Signature of mat-palette.

  mat.define-palette(
    scss variable with shape { key1: color, key2: color, key3: color, contrast: { ...repeat all keys } }, (example above)
    key of primary color,
    key of darken color,
    key of lighten color,
    (optional) key of primary contrast - default contrast[key_of_primary_color]
  )
*/

$app-primary: mat.define-palette($protct-primary, main, dark, light);
$app-accent: mat.define-palette($protct-accent, main, dark, light);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette($protct-warn, main, dark, light);

// App typography configuration.
$app-typography: mat.define-legacy-typography-config(
  $font-family: var(--font-family),
);

// Create the theme object.
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: $app-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($app-theme);

// Customize material sidenav.
.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
}

// Customize mat-menu panel.
.mat-menu.mat-menu-panel {
  max-width: none;
  min-width: 240px;
  margin: 0 var(--space-l);
}

.mat-menu .mat-menu-content,
.mat-menu .mat-menu-content:not(:empty) {
  padding: 0;
}

// Override mat-checkbox-label because `label > span` styles from control.css affected on it.
.mat-checkbox-label {
  font-weight: normal;
  margin-bottom: 0;
}

// Override mat-paginator buttons.
.mat-paginator-container {
  .mat-button-base.mat-button-disabled {
    background: transparent;
  }
}

// Override mat-progress-bar.
.mat-progress-bar-buffer {
  background-color: var(--primary-light-color);
}

mat-progress-bar.mat-progress-bar {
  position: absolute;
  top: 0;
  left: 0;

  // Loading progress bar should be on top.
  z-index: 1;
}

// Override mat-tab labels.
.mat-tab-label {
  font-size: 1.15rem;
}

// Override mat-table styles.
.mat-paginator {
  background-color: var(--surface-background-color);
}

.mat-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  display: table;
  border-collapse: collapse;
  margin: 0px;
  background-color: var(--surface-background-color);
}

.mat-row,
.mat-header-row,
.mat-footer-row {
  display: table-row;
  border-bottom-width: 0;
}

.mat-table .mat-row:hover {
  background-color: var(--primary-light-hover-color);
}

.mat-header-row {
  font-weight: 500;
  border-bottom-width: 1px;
}

.mat-footer-row {
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-color: var(--border-color);
}

.mat-cell,
.mat-header-cell,
.mat-footer-cell {
  height: 72px;
  word-wrap: initial;
  display: table-cell;
  padding: 0px var(--space-xs);
  line-break: unset;
  width: auto;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
}

.mat-header-cell {
  height: 56px;
  font-weight: 500;
  font-size: var(--font-size);
  color: var(--base-color);
}

.mat-footer-cell {
  height: 48px;
}

.mat-cell:first-of-type,
.mat-header-cell:first-of-type,
.mat-footer-cell:first-of-type {
  padding-left: var(--space-l);
}

.mat-cell:last-of-type,
.mat-header-cell:last-of-type,
.mat-footer-cell:last-of-type {
  padding-right: var(--space-l);
}

.mat-table td.mat-cell {
  padding: 0 var(--space-xs);
  border-bottom-width: 0;
}

// Override mat-checkbox styles.
label.mat-checkbox-layout {
  white-space: initial;
}

// Override snackbar styles.
snack-bar-container.mat-snack-bar-container {
  position: absolute;
  min-width: 230px;
  padding: 10px;
}

// Override mat radio label for on text wrapping.
label.mat-radio-label {
  white-space: normal;
}

// Override mat selection list.
mat-list-option.mat-list-item.mat-list-option {
  height: auto;
  min-height: 48px;
}

mat-list-option.mat-list-item.mat-list-option .mat-list-item-content.mat-list-item-content-reverse {
  padding: 16px;
}

// Override autocomplete mat option.
.mat-autocomplete-panel .mat-option {
  min-height: 48px;
  height: auto;
}

// Override mat-button toggle styles.
mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  border-radius: var(--radius);
}

.mat-button-toggle {
  min-width: 76px;
  min-height: 48px;
}

.mat-button-toggle-checked {
  background-color: var(--primary-color);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: var(--primary-contrast-color);
}
