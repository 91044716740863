.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-field-container {
  margin-bottom: var(--space-xs);
}

.form-page-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  flex-wrap: wrap;
  gap: var(--space-xs);
}
