/* Basic form field styles. */
// TODO: (Sakhno V): Remove when all form fields will be updated.
.form-field {
  width: 100%;
  padding: var(--space-xs);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  background-color: var(--surface-background-color);

  .mat-form-field-infix {
    padding: 0;
  }

  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label-wrapper {
    top: -1.6rem;
    padding-top: 1.6rem;
  }

  .mat-form-field-label {
    color: var(--base-secondary-color);
  }

  .mat-select-arrow-wrapper {
    vertical-align: top;
  }

  .mat-button-wrapper {
    vertical-align: bottom;
  }
}

/* Form field with float label state. */

.form-field.mat-form-field-should-float {
  .mat-form-field-label {
    font-size: var(--small-font-size);
  }

  .mat-form-field-label-wrapper {
    top: -0.84375em;
    padding-top: 0.84375em;
  }

  .mat-form-field-label {
    color: var(--base-color);
  }
}

/* Form field in focused active state. */

.form-field.mat-focused {
  border-color: var(--form-field-active-color);

  .mat-form-field-label {
    color: var(--form-field-active-color);
  }
}

.mat-form-field.active.active .mat-form-field-label  {
  color: var(--form-field-active-color);
}

/** Form field with invalid status. */

.form-field.mat-form-field-invalid {
  border-color: var(--error-font-color);

  .mat-form-field-label {
    color: var(--base-secondary-color);
  }
}

/** Disabled form field. */
.form-field.mat-form-field-disabled {
  background-color: var(--base-background-color);
}
